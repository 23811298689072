import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import InputAdornment from '@material-ui/core/InputAdornment';

import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import MessageBox from '~components/forms/MessageBox';

const CheckoutCouponForm = ({ handleCouponSubmit, handleCouponCodeInputChange, state }) => {
  const { register, handleSubmit } = useForm();
  const { isLoading, isError, error, data } = state;

  return (
    <form onSubmit={handleSubmit(handleCouponSubmit)}>
      {isError && <MessageBox text={error} isError />}

      {data && <MessageBox text={`Coupon "${data.id}" applied successfully!`} />}

      <TextInput
        onChange={handleCouponCodeInputChange}
        label="Enter coupon"
        name="coupon"
        defaultValue={data && data.id}
        inputRef={register}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ButtonSubmit>Apply</ButtonSubmit>
            </InputAdornment>
          )
        }}
        required
        disabled={isLoading}
      />
    </form>
  );
};
CheckoutCouponForm.propTypes = {
  handleCouponSubmit: PropTypes.func.isRequired,
  handleCouponCodeInputChange: PropTypes.func,
  state: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    error: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
};
CheckoutCouponForm.defaultProps = {
  handleCouponCodeInputChange: () => {}
};

export default CheckoutCouponForm;
