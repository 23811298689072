import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import localStorage from 'store2';
import { isNil } from 'lodash';

import useCheckout from '~hooks/useCheckout';
import { useRegistration, useFacebookLogin } from '~hooks/useAuth';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import SEO from '~layout/SearchEngineOptimization';
import FlowLayout from '~components/checkout/FlowLayout';
import RegisterForm from '~components/checkout/RegisterForm';
import CheckoutSummary from '~components/checkout/CheckoutSummary';
import LoadingSpinner from '~components/LoadingSpinner';
import products from '~utils/products';
import { useStripeCoupon } from '~hooks/usePurchase';
import useIsPageLoaded from '~hooks/useIsPageLoaded';
import useConversionFramework from '~hooks/useConversionFramework';
import RedirectWithNotice from '~src/layout/RedirectWithNotice';

const seo = {
  seo_title: 'Register',
  seo_description: 'Sign-up to get free access to Aleph Beta’s Torah videos.',
  seo_keywords: 'register aleph beta'
};

const LOCAL_STORAGE_KEY = 'apple-sign-in-url';

function isCheckoutForFreeProduct(checkoutState) {
  return checkoutState && checkoutState.product && checkoutState.product.id === -1;
}

function hasProduct(checkoutState) {
  return checkoutState && checkoutState.product;
}

const Register = ({ location = {} }) => {
  const isPageLoaded = useIsPageLoaded();
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isUserStateLoading, isLoggedIn } = session;

  const [checkoutState, setCheckoutState] = useCheckout();
  const { state: couponState, getCoupon } = useStripeCoupon();
  const [regState, register] = useRegistration();
  const [fbState, loginWithFacebook] = useFacebookLogin();
  const { state } = location;
  const { appleError } = state || {};

  const isLoading = regState.isLoading || fbState.isLoading;
  const isError = regState.isError || fbState.isError;
  const error = regState.error || fbState.error;

  const freePlanId = -1;

  localStorage.set(LOCAL_STORAGE_KEY, 'register');

  /**
   * Logic to redirect user to members site when the marketing site feature is enabled
   * Just get the query parameters applicable for the express-checkout from the checkout state
   * and redirect user to the express-checkout of the members website
   */
  const [redirection, setRedirection] = useState(null);
  useEffect(() => {
    if (!isMarketingSite) {
      return;
    }

    const planId = hasProduct(checkoutState) ? checkoutState.product.id : freePlanId;
    const period = checkoutState?.billingCycle || 'year';

    const url = new URL('express-checkout', process.env.GATSBY_MEMBERS_SITE_URL);
    const searchParams = {
      planId,
      period,
      isPayingFees: checkoutState?.isPayingFees,
      coupon: checkoutState?.coupon
    };
    Object.keys(searchParams).forEach(key => {
      if (!isNil(searchParams[key])) {
        url.searchParams.append(key, searchParams[key]);
      }
    });
    setRedirection({ url: url.toString() });
  }, [checkoutState, setCheckoutState, isMarketingSite]);

  useEffect(() => {
    if (isUserStateLoading || isMarketingSite || isMarketingSite === null) {
      return;
    }

    if (!hasProduct(checkoutState)) {
      const product = products.find(({ id }) => id === freePlanId);
      setCheckoutState({
        product
      });
    }

    if (checkoutState.coupon) {
      getCoupon({ coupon: checkoutState.coupon });
    }
    if (isLoggedIn) {
      if (checkoutState && checkoutState.product) {
        navigate(
          isCheckoutForFreeProduct(checkoutState) ? '/checkout/confirmation' : '/checkout/billing',
          { replace: true }
        );
      }
    }
  }, [checkoutState, isUserStateLoading, isLoggedIn, setCheckoutState, isMarketingSite]);

  if (isMarketingSite && redirection?.url) {
    return <RedirectWithNotice url={redirection.url} seconds={3} />;
  }

  if (isUserStateLoading || isMarketingSite || isMarketingSite === null || !isPageLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SEO {...seo} />

      <FlowLayout
        title="Create Your Account"
        subtitle="Already have an account?"
        to="/checkout/login"
        linkTitle="Log in here"
      >
        <RegisterForm
          handleFormSubmit={register}
          handleFacebookSubmit={loginWithFacebook}
          state={{ isLoading, isError, error }}
          appleError={appleError}
        />

        {checkoutState && checkoutState.product && (
          <CheckoutSummary {...checkoutState} couponData={couponState.data} />
        )}
      </FlowLayout>
    </>
  );
};

Register.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({})
  })
};
Register.defaultProps = {
  location: {}
};

export default Register;
