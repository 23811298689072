import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, Typography } from '@material-ui/core';
import TermsPopup from '~containers/terms/TermsPopup';
import { useTestId } from '~utils/set-testid';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10
  },
  gotoLink: {
    color: '#0068FF',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const TermsConsentInput = ({ onChange, hasAccepted }) => {
  const classes = useStyles();

  const [showTermsPopUp, setShowTermsPopUp] = useState(false);

  const handleAcceptTerms = (event, checked) => onChange(checked);
  const handleShowTerms = () => setShowTermsPopUp(true);
  const handleCloseTerms = () => setShowTermsPopUp(false);
  const consentCheckTestId = useTestId('toggle-consent');

  return (
    <>
      {showTermsPopUp && <TermsPopup id="terms" isPopupOpen handlePopupClose={handleCloseTerms} />}
      <div className={classes.row}>
        <Checkbox
          checked={hasAccepted}
          onChange={handleAcceptTerms}
          color="primary"
          {...consentCheckTestId}
        />

        <Typography variant="body2">
          I agree to the{' '}
          <span
            tabIndex={0}
            className={classes.gotoLink}
            onClick={handleShowTerms}
            role="button"
            onKeyDown={event => {
              if (
                ['keydown', 'keypress'].includes(event.type) &&
                ['Enter', ' '].includes(event.key)
              ) {
                handleShowTerms();
              }
            }}
          >
            Terms of Sale
          </span>
          , including the Cancellation and Refund Policy.
        </Typography>
      </div>
    </>
  );
};

TermsConsentInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  hasAccepted: PropTypes.bool.isRequired
};

export default TermsConsentInput;
