export const getYearsAhead = (startingYear, numberOfYears = 10) => {
  // validate inputs
  if (!Number.isInteger(startingYear) || startingYear < 0) {
    throw Error('Starting year should be defined as an positive integer');
  }
  if (!Number.isInteger(numberOfYears)) {
    throw Error('Number of years should be an integer');
  }
  if (numberOfYears <= 0) {
    return [];
  }
  const years = [];
  for (let i = 0; i < numberOfYears; i += 1) {
    years.push(String(startingYear + i));
  }
  return years;
};
