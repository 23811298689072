import React, { useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { Tooltip } from '@material-ui/core';
import { ButtonSubmit, PaypalSubmit, PaypalExpressCheckoutButton } from '~components/form-buttons';
import CreditCardForm from '~components/forms/CreditCardForm';
import CheckoutCouponForm from '~components/forms/CheckoutCouponForm';
import LoadingSpinner from '~components/LoadingSpinner';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { useTestId } from '~src/utils/set-testid';

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const PaymentMethodSelector = ({
  handleCreditCardFormCollapse,
  product,
  handlePayPalExpressSuccess,
  handlePayPalSubmit,
  isPrivilegedMember
}) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const creditCardTestId = useTestId('credit-card-button');

  return (
    <>
      <ButtonSubmit type="button" {...creditCardTestId} onClick={handleCreditCardFormCollapse}>
        <CreditCardIcon className={classes.leftIcon} />
        Pay with Credit Card
      </ButtonSubmit>
      {handlePayPalExpressSuccess || handlePayPalSubmit ? (
        <>
          <Box py={1}>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              OR
            </Typography>
          </Box>
          {isLoading && <LoadingSpinner />}
          {product.isRecurring ? (
            <>
              {!isLoading && (
                <PaypalSubmit
                  onClick={() => {
                    handlePayPalSubmit();
                    setLoading(true);
                  }}
                  disabled={isLoading}
                />
              )}
            </>
          ) : (
            <PaypalExpressCheckoutButton
              onSuccess={handlePayPalExpressSuccess}
              amount={isPrivilegedMember && product.id === 31 ? 20 : product.yearlyPrice}
            />
          )}
        </>
      ) : (
        <Box py={1}>
          <Typography variant="subtitle1" color="textSecondary" align="center">
            (PayPal is not available for this payment)
          </Typography>
        </Box>
      )}
    </>
  );
};
PaymentMethodSelector.propTypes = {
  handleCreditCardFormCollapse: PropTypes.func.isRequired,
  product: PropTypes.shape({
    isRecurring: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    yearlyPrice: PropTypes.number.isRequired
  }).isRequired,
  handlePayPalExpressSuccess: PropTypes.func.isRequired,
  handlePayPalSubmit: PropTypes.func.isRequired,
  isPrivilegedMember: PropTypes.bool.isRequired
};

const noop = () => {};

const StripePaymentForm = ({
  product,
  handleStripeSubmit,
  handleCouponSubmit,
  state,
  couponState,
  handleCreditCardFormCollapse,
  hasCoupon
}) => {
  const { purchaseButtonTitle, hasCoupon: isProductCouponsEnabled } = product;
  const [isCouponFormCollapsed, setIsCouponFormCollapsed] = useState(hasCoupon);
  const handleCouponFormCollapse = () => {
    setIsCouponFormCollapsed(collapsed => !collapsed);
  };

  const { isLoading } = state;
  const { data: coupon } = couponState;
  const appliedCouponId = coupon && coupon.id;
  const didApplyCoupon = !!appliedCouponId;

  const [isUnfinishedCouponEntered, setIsUnfinishedCouponEntered] = useState(didApplyCoupon);
  const handleCouponCodeInputChange = event => {
    setIsUnfinishedCouponEntered(event.target.value.length > 0);
  };

  return (
    <>
      <CreditCardForm
        handleFormSubmit={handleStripeSubmit}
        state={state}
        couponId={appliedCouponId}
        purchaseButtonTitle={purchaseButtonTitle}
        isCouponEditing={didApplyCoupon ? false : isUnfinishedCouponEntered}
      />
      {isProductCouponsEnabled && (
        <ButtonSubmit
          type="button"
          size="small"
          variant="text"
          color="primary"
          fullWidth
          onClick={handleCouponFormCollapse}
          disabled={isLoading}
        >
          {isCouponFormCollapsed ? 'Enter your coupon below' : 'Have a coupon code?'}
        </ButtonSubmit>
      )}

      {(didApplyCoupon || isCouponFormCollapsed) && (
        <CheckoutCouponForm
          handleCouponSubmit={handleCouponSubmit}
          handleCouponCodeInputChange={didApplyCoupon ? noop : handleCouponCodeInputChange}
          state={couponState}
        />
      )}

      {!didApplyCoupon && (
        <ButtonSubmit
          type="button"
          size="small"
          variant="text"
          onClick={handleCreditCardFormCollapse}
          disabled={isLoading}
        >
          Change Method
        </ButtonSubmit>
      )}
      {didApplyCoupon && (
        <Tooltip title="You cannot change the payment method after you applied the coupon. Please refresh the page to do so.">
          <div>
            <ButtonSubmit
              type="button"
              size="small"
              variant="text"
              onClick={handleCreditCardFormCollapse}
              disabled
            >
              Change Method
            </ButtonSubmit>
          </div>
        </Tooltip>
      )}
    </>
  );
};
StripePaymentForm.propTypes = {
  handleStripeSubmit: PropTypes.func.isRequired,
  handleCouponSubmit: PropTypes.func.isRequired,
  handleCreditCardFormCollapse: PropTypes.func.isRequired,
  product: PropTypes.shape({
    purchaseButtonTitle: PropTypes.string.isRequired,
    hasCoupon: PropTypes.bool.isRequired
  }).isRequired,
  state: PropTypes.shape({ isLoading: PropTypes.bool.isRequired }).isRequired,
  couponState: PropTypes.shape({ data: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  hasCoupon: PropTypes.bool
};

StripePaymentForm.defaultProps = {
  hasCoupon: false
};

const CheckoutPayment = ({
  handleStripeSubmit,
  handlePayPalSubmit,
  handleCouponSubmit,
  handlePayPalExpressSuccess,
  state,
  couponState,
  checkoutState
}) => {
  const { session } = useUserContext();
  const { isPrivilegedMember } = session;
  const { product, hasCoupon } = checkoutState;
  const [isCreditCardFormCollapsed, setIsCreditCardFormCollapsed] = useState(!hasCoupon);

  const handleCreditCardFormCollapse = () => {
    setIsCreditCardFormCollapsed(collapsed => !collapsed);
  };

  return (
    <Box my={2}>
      {isCreditCardFormCollapsed ? (
        <PaymentMethodSelector
          handleCreditCardFormCollapse={handleCreditCardFormCollapse}
          product={product}
          handlePayPalExpressSuccess={handlePayPalExpressSuccess}
          handlePayPalSubmit={handlePayPalSubmit}
          isPrivilegedMember={isPrivilegedMember}
        />
      ) : (
        <StripePaymentForm
          product={product}
          couponState={couponState}
          state={state}
          handleStripeSubmit={handleStripeSubmit}
          hasCoupon={hasCoupon}
          handleCouponSubmit={handleCouponSubmit}
          handleCreditCardFormCollapse={handleCreditCardFormCollapse}
        />
      )}
    </Box>
  );
};

CheckoutPayment.propTypes = {
  handleStripeSubmit: PropTypes.func.isRequired,
  handlePayPalSubmit: PropTypes.func.isRequired,
  handleCouponSubmit: PropTypes.func.isRequired,
  handlePayPalExpressSuccess: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  couponState: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    error: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  checkoutState: PropTypes.shape().isRequired
};

export default CheckoutPayment;
