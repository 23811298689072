import React, { useEffect, useState } from 'react';

import { Router } from '@reach/router';

import Page from '~layout/Page';
import { isNil } from 'lodash';
import Login from '~containers/checkout/Login';
import Register from '~containers/checkout/Register';
import MagicLink from '~containers/checkout/MagicLink';
import Billing from '~containers/checkout/Billing';
import Confirmation from '~containers/checkout/Confirmation';
import useConversionFramework from '~hooks/useConversionFramework';
import LoadingSpinner from '~components/LoadingSpinner';
import useCheckout from '~hooks/useCheckout';
import { PRODUCT_IDS } from '~utils/products';
import RedirectWithNotice from '~layout/RedirectWithNotice';

function hasProduct(checkoutState) {
  return checkoutState && checkoutState.product;
}

const freePlanId = -1;

const Checkout = () => {
  const { isMarketingSite } = useConversionFramework();
  const [checkoutState, setCheckoutState] = useCheckout();
  const [redirection, setRedirection] = useState(null);

  useEffect(
    () => () => {
      // FIXME: convert it into the persistent state usage like JWT is
      if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.removeItem('checkout');
      }
    },
    []
  );

  useEffect(() => {
    if (!isMarketingSite) {
      return;
    }

    const planId = hasProduct(checkoutState) ? checkoutState.product.id : freePlanId;
    const period = checkoutState?.billingCycle || 'year';

    const url = new URL('express-checkout', process.env.GATSBY_MEMBERS_SITE_URL);
    const searchParams = {
      planId,
      period,
      isPayingFees: checkoutState?.isPayingFees,
      coupon: checkoutState?.coupon
    };

    // Custom donations should set an amount
    if (planId === PRODUCT_IDS.Donation) {
      searchParams.amount =
        period === 'year'
          ? checkoutState?.product?.monthlyPrice
          : checkoutState?.product?.yearlyPrice;
    }

    Object.keys(searchParams).forEach(key => {
      if (!isNil(searchParams[key])) {
        url.searchParams.append(key, searchParams[key]);
      }
    });
    setRedirection({ url: url.toString() });
  }, [checkoutState, setCheckoutState, isMarketingSite]);

  if (isMarketingSite && redirection?.url) {
    return <RedirectWithNotice url={redirection.url} seconds={3} />;
  }

  if (isMarketingSite) {
    return <LoadingSpinner />;
  }

  return (
    <Page>
      <Router>
        <Register path="/checkout/register" default />
        <Login path="/checkout/login" />
        <MagicLink path="/checkout/magic-link" />
        <Billing path="/checkout/billing" />
        <Confirmation path="/checkout/confirmation" />
      </Router>
    </Page>
  );
};

export default Checkout;
