import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import useCheckout from '~hooks/useCheckout';
import { useStripeCoupon, usePurchase } from '~hooks/usePurchase';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import SEO from '~layout/SearchEngineOptimization';

import FlowLayout from '~components/checkout/FlowLayout';
import CheckoutPayment from '~components/checkout/CheckoutPayment';
import CheckoutSummary from '~components/checkout/CheckoutSummary';
import CheckoutTerms from '~components/checkout/CheckoutTerms';
import LoadingSpinner from '~components/LoadingSpinner';
import useConversionFramework from '~hooks/useConversionFramework';

const seo = {
  seo_title: 'Billing',
  seo_description: 'Set up your billing access to join Aleph Beta.',
  seo_keywords: 'billing aleph beta'
};

function isCheckoutForFreeProduct(checkoutState) {
  return checkoutState.product && checkoutState.product.id === -1;
}

function hasProduct(checkoutState) {
  return Boolean(checkoutState && checkoutState.product);
}

const Billing = () => {
  const [checkoutStateRaw] = useCheckout();
  const checkoutState = checkoutStateRaw || {}; // useCheckout can return null
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isLoggedIn, isUserStateLoading } = session;

  // Stripe Coupon  process
  const { state: couponState, getCoupon } = useStripeCoupon();
  const { data: coupon } = couponState;

  // Purchase process
  const { state: purchaseState, purchase } = usePurchase(
    checkoutState.product,
    checkoutState.billingCycle,
    coupon,
    checkoutState.isGift,
    checkoutState.isPayingFees
  );

  useEffect(() => {
    if (isMarketingSite) {
      navigate('/');
    }
  }, [isMarketingSite]);

  const addNotesToPayload = e => {
    let additionalData = { ...e };
    if (checkoutState.donationNotes) {
      additionalData = { ...additionalData, donationNotes: checkoutState.donationNotes };
    }
    if (checkoutState.isWartimeDedication) {
      additionalData = {
        ...additionalData,
        isWartimeDedication: checkoutState.isWartimeDedication
      };
    }
    return additionalData;
  };

  let handlePayPalSubmit = null;
  let handlePayPalExpressSuccess = null;
  if (!checkoutState.product.payPalPaymentDisabled) {
    handlePayPalSubmit = e => {
      const payloadWithNotes = addNotesToPayload(e) || e;
      purchase('paypal', payloadWithNotes);
    };

    /**
     * This function is compatible with the `onSuccess` handler for the <https://github.com/Luehang/react-paypal-button-v2> component.
     * As part of the shift to using that 3rd party library, this handler got minimal changes to still conform to other code.
     * However as a @TODO all of our donation machinery, including the backend, can be greatly simplified thanks to this library.
     *
     * @param {Object} details PayPal response with the customer info, creation date and some other stuff we don't actually care about.
     * @param {{orderID: string, payerID: string}} data PayPal internal IDs for this successful transaction.
     */
    handlePayPalExpressSuccess = (details, data) => {
      // `formData` is the name of the argument in the `purchase` method source code.
      const formData = addNotesToPayload({
        email: details.payer && details.payer.email_address,
        paymentToken: data.orderID
      });
      purchase('paypal', formData);
    };
  }

  const handleStripeSubmit = e => {
    const payloadWithNotes = addNotesToPayload(e) || e;
    purchase('stripe', payloadWithNotes);
  };

  useEffect(() => {
    if (isUserStateLoading) {
      return;
    }

    if (!isLoggedIn) {
      navigate('/subscribe');
      return;
    }

    if (!hasProduct(checkoutState)) {
      navigate('/subscribe');
    }
    if (checkoutState.coupon) {
      getCoupon({ coupon: checkoutState.coupon });
    }

    if (isCheckoutForFreeProduct(checkoutState)) {
      navigate('/checkout/confirmation', { replace: true });
    }
  }, [checkoutState, isUserStateLoading, isLoggedIn]);

  return (
    <>
      <SEO {...seo} />
      {!isUserStateLoading && hasProduct(checkoutState) ? (
        <FlowLayout title="Billing Information">
          <>
            <CheckoutPayment
              handleStripeSubmit={handleStripeSubmit}
              handlePayPalSubmit={handlePayPalSubmit}
              handleCouponSubmit={getCoupon}
              handlePayPalExpressSuccess={handlePayPalExpressSuccess}
              state={purchaseState}
              couponState={couponState}
              checkoutState={checkoutState}
            />

            <CheckoutTerms />
          </>

          <CheckoutSummary couponData={couponState.data} {...checkoutState} />
        </FlowLayout>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default Billing;
