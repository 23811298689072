import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useForm } from 'react-hook-form';

import MessageBox from '~components/forms/MessageBox';
import { TextInput, EmailInput, PasswordInput } from '~components/form-inputs';
import { ButtonSubmit, FacebookButtonSubmit, AppleSignInButton } from '~components/form-buttons';
import { useTestId } from '~utils/set-testid';

const RegisterForm = ({ handleFormSubmit, handleFacebookSubmit, state, appleError }) => {
  const { register: registerInput, handleSubmit } = useForm();
  const { isLoading, isError, error } = state;
  const firstNameTestId = useTestId('register-form-first-name');
  const lastNameTestId = useTestId('register-form-last-name');
  const emailTestId = useTestId('register-form-email');
  const passwordTestId = useTestId('register-form-password');
  const buttonTestId = useTestId('register-form-submit-button');

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {(isError || appleError) && <MessageBox text={error || appleError} isError />}

        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              label="First Name"
              name="meta_first_name"
              autoComplete="given-name"
              inputRef={registerInput}
              disabled={isLoading}
              inputProps={{ ...firstNameTestId }}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              label="Last Name"
              name="meta_last_name"
              autoComplete="family-name"
              inputRef={registerInput}
              inputProps={{ ...lastNameTestId }}
              disabled={isLoading}
              required
            />
          </Grid>
        </Grid>

        <EmailInput
          name="username"
          inputRef={registerInput}
          inputProps={{ ...emailTestId }}
          disabled={isLoading}
        />

        <PasswordInput
          inputRef={registerInput}
          inputProps={{ ...passwordTestId }}
          disabled={isLoading}
        />

        <ButtonSubmit disabled={isLoading} {...buttonTestId}>
          Create Account
        </ButtonSubmit>
      </form>

      <Box py={1}>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          OR
        </Typography>
      </Box>

      <FacebookButtonSubmit callback={handleFacebookSubmit} disabled={isLoading}>
        Create Account with Facebook
      </FacebookButtonSubmit>

      <Box py={1}>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          OR
        </Typography>
      </Box>

      <AppleSignInButton />
    </>
  );
};

RegisterForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleFacebookSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  appleError: PropTypes.string
};
RegisterForm.defaultProps = {
  appleError: null
};
export default RegisterForm;
