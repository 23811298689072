import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';

const StyledInput = withStyles(theme => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '18px 26px 17px 12px',
    marginTop: '7px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: theme.palette.background.paper
    }
  }
}))(InputBase);

const CheckoutSelect = props => {
  const { options, placeholder, ...rest } = props;
  const [value, setValue] = useState(placeholder);

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Select value={value} input={<StyledInput />} onChange={handleChange} {...rest}>
      <MenuItem value={placeholder} disabled>
        {placeholder}
      </MenuItem>
      {options &&
        options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
    </Select>
  );
};

CheckoutSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired
};

export default CheckoutSelect;
