import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import { getYearsAhead } from '~src/utils/date-helper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { Tooltip } from '@material-ui/core';
import MessageBox from '~components/forms/MessageBox';
import TextInput from '~components/form-inputs/TextInput';
import CheckoutSelect from '~components/inputs/CheckoutSelect';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import LoadingSpinner from '~components/LoadingSpinner';
import TermsConsentInput from '~containers/terms/TermsConsentInput';

import { useTestId } from '~utils/set-testid';

const NUMBER_OF_YEARS_AHEAD = 11;
const CURRENT_YEAR = new Date().getFullYear();

const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const YEARS = getYearsAhead(CURRENT_YEAR, NUMBER_OF_YEARS_AHEAD);

const getSubmitDisabledToolTipMessage = (hasAcceptedTerms, isCouponEditing) => {
  if (isCouponEditing) {
    return 'Please either press "apply" button for the coupon or erase the entered coupon code';
  }
  if (!hasAcceptedTerms) {
    return 'Please accept Terms of Sales above by pressing the checkbox';
  }
  return '';
};

const CreditCardForm = ({
  handleFormSubmit,
  state,
  couponId,
  purchaseButtonTitle,
  isCouponEditing
}) => {
  const { register, handleSubmit } = useForm();
  const { isLoading, isError, error } = state;
  const [hasError, setHasError] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const isSubmitDisabled = !hasAcceptedTerms || isCouponEditing;

  const cardNumberTestId = useTestId('credit-card-form-card-number');
  const cardMonthTestId = useTestId('credit-card-form-card-month');
  const cardYearTestId = useTestId('credit-card-form-card-year');
  const cardCvcTestId = useTestId('credit-card-form-card-cvc');
  const buttonTestId = useTestId('credit-card-form-submit-button');

  useEffect(() => {
    if (isError) {
      setHasError(isError);
    }
  }, [isError, state]);

  const handleCheckoutRef = data => {
    if (data) {
      register(data.node);
    }
  };

  const handleFocus = () => {
    if (isError) {
      setHasError(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {hasError && !isLoading && <MessageBox text={error} isError />}

      <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <TextInput
            label="Card Number"
            name="card.number"
            required
            autoComplete="cc-number"
            inputRef={register}
            onFocus={handleFocus}
            inputProps={{
              minLength: 14,
              ...cardNumberTestId
            }}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckoutSelect
            name="card.exp_month"
            options={MONTHS}
            placeholder="MM"
            autoComplete="cc-exp-month"
            inputProps={{ ...cardMonthTestId }}
            inputRef={handleCheckoutRef}
            disabled={isLoading}
            onFocus={handleFocus}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckoutSelect
            name="card.exp_year"
            options={YEARS}
            placeholder="YYYY"
            autoComplete="cc-exp-year"
            inputRef={handleCheckoutRef}
            inputProps={{ ...cardYearTestId }}
            disabled={isLoading}
            onFocus={handleFocus}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextInput
            label="Security Code"
            name="card.cvc"
            required
            type="number"
            autoComplete="cc-csc"
            inputRef={register}
            inputProps={{ ...cardCvcTestId }}
            disabled={isLoading}
            onFocus={handleFocus}
          />
        </Grid>

        <Grid item xs={12}>
          <TermsConsentInput hasAccepted={hasAcceptedTerms} onChange={setHasAcceptedTerms} />
        </Grid>

        <Grid item xs={12}>
          {isLoading && <LoadingSpinner />}

          {!isLoading && isSubmitDisabled && (
            <Tooltip title={getSubmitDisabledToolTipMessage(hasAcceptedTerms, isCouponEditing)}>
              <div>
                <ButtonSubmit {...buttonTestId} disabled>
                  {purchaseButtonTitle}
                </ButtonSubmit>
              </div>
            </Tooltip>
          )}
          {!isLoading && !isSubmitDisabled && (
            <ButtonSubmit {...buttonTestId} disabled={isLoading}>
              {purchaseButtonTitle}
            </ButtonSubmit>
          )}
        </Grid>
      </Grid>

      {/* Hidden input to submit coupon id */}
      {couponId && (
        <Hidden xsUp implementation="css">
          <TextInput
            label="Coupon"
            name="coupon"
            defaultValue={couponId}
            type="text"
            inputRef={register}
            disabled={isLoading}
          />
        </Hidden>
      )}
    </form>
  );
};

CreditCardForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    error: PropTypes.string
  }).isRequired,
  couponId: PropTypes.string,
  purchaseButtonTitle: PropTypes.string.isRequired,
  isCouponEditing: PropTypes.bool
};
CreditCardForm.defaultProps = {
  couponId: null,
  isCouponEditing: false
};

export default CreditCardForm;
