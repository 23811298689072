import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Dialog from '~components/Dialog';
import unfurl from '~prismic/unfurl/terms';
import { makeStyles } from '@material-ui/styles';
import loadable from '@loadable/component';
import LoadingSpinner from '~components/LoadingSpinner';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  body: {
    padding: '0px 20px 20px 20px',
    marginTop: -20
  },
  buttonStyle: {
    right: '20px',
    top: '20px',
    borderRadius: '50%',
    zIndex: 1
  },
  iconStyle: {
    fontSize: '2rem',
    color: '#0000004D'
  }
});

const query = graphql`
  {
    prismicTerms {
      _previewable
      data {
        body {
          ... on PrismicTermsDataBodyTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicTermsDataBodyTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
        }
      }
    }
  }
`;

const SliceToComponent = loadable(() => import('~components/slices/slices'), {
  fallback: (
    <div>
      <LoadingSpinner />
    </div>
  )
});

const TermsPopup = ({ id, handlePopupClose, isPopupOpen }) => {
  const classes = useStyles();
  const prismicData = useStaticQuery(query);
  const { slices } = unfurl(prismicData);

  return (
    <Dialog
      isOpen={isPopupOpen}
      BackdropProps={{}}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      onClose={handlePopupClose}
      maxWidth="md"
      id={id}
      background="rgba(255, 255, 255, 0.96)"
    >
      <div className={classes.header}>
        <IconButton aria-label="close" className={classes.buttonStyle} onClick={handlePopupClose}>
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
      </div>
      <div className={classes.body}>
        <SliceToComponent slices={slices} />
      </div>
    </Dialog>
  );
};

TermsPopup.propTypes = {
  id: PropTypes.string.isRequired,
  handlePopupClose: PropTypes.func.isRequired,
  isPopupOpen: PropTypes.bool
};

TermsPopup.defaultProps = {
  isPopupOpen: false
};

export default TermsPopup;
