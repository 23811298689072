/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { useGetMagicLink } from '~hooks/useAuth';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import useCheckout from '~hooks/useCheckout';

import SEO from '~layout/SearchEngineOptimization';
import FlowLayout from '~components/checkout/FlowLayout';
import MagicLinkForm from '~components/checkout/MagicLinkForm';
import CheckoutSummary from '~components/checkout/CheckoutSummary';
import LoadingSpinner from '~components/LoadingSpinner';
import { useStripeCoupon } from '~hooks/usePurchase';
import useIsPageLoaded from '~hooks/useIsPageLoaded';
import useConversionFramework from '~hooks/useConversionFramework';

const seo = {
  seo_title: 'Send A Magic Link Via Email',
  seo_description: 'Click on the link and you will be directed to your Aleph Beta account.',
  seo_keywords: 'aleph beta email magic link'
};

function isCheckoutForFreeProduct(checkoutState) {
  return checkoutState && checkoutState.product && checkoutState.product.id === -1;
}

const MagicLink = ({ location }) => {
  const { state } = location;
  const prefilledUsername = state && state.prefilledUsername;
  const [checkoutState] = useCheckout();
  const { state: couponState, getCoupon } = useStripeCoupon();

  const [magicLinkState, getMagicLink] = useGetMagicLink();

  const { session } = useUserContext();
  const { isUserStateLoading, isLoggedIn } = session;
  const isPageLoaded = useIsPageLoaded();
  const { isMarketingSite } = useConversionFramework();

  useEffect(() => {
    if (isUserStateLoading || isMarketingSite || isMarketingSite === null) {
      return;
    }
    if (checkoutState.coupon) {
      getCoupon({ coupon: checkoutState.coupon });
    }
    if (isLoggedIn) {
      if (checkoutState && checkoutState.product) {
        navigate(
          isCheckoutForFreeProduct(checkoutState) ? '/checkout/confirmation' : '/checkout/billing'
        );
      } else {
        navigate('/');
      }
    }
  }, [checkoutState, isUserStateLoading, isLoggedIn, isMarketingSite]);

  if (isUserStateLoading || !isPageLoaded || isMarketingSite || isMarketingSite === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SEO {...seo} />

      <FlowLayout
        title="Forgot Password"
        subtitle="Please enter your email address. We'll email you a link login to reset your password. Remembered your password?"
        to="/checkout/login"
        linkTitle="Go back to login."
      >
        <MagicLinkForm
          prefilledUsername={prefilledUsername}
          handleFormSubmit={getMagicLink}
          state={magicLinkState}
        />

        {checkoutState && checkoutState.product && (
          <CheckoutSummary {...checkoutState} couponData={couponState.data} />
        )}
      </FlowLayout>
    </>
  );
};

export default MagicLink;
