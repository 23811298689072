import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import Link from '~components/Link';
import Divider from '~components/Divider';
import { getProductPrice } from '~utils/products';
import { formatPrice } from '~utils/subscribe-helpers';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles({
  inheritDisplay: {
    display: 'inherit'
  }
});

const CheckoutConfirmationSummary = ({
  product,
  billingCycle,
  coupon /* , isGift */,
  isPayingFees
}) => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { user } = session;

  let price = getProductPrice(product, billingCycle === 'year', isPayingFees);

  const isFreePlan = product && product.id === -1;

  if (coupon) {
    if (coupon.amount_off) {
      price -= coupon.amount_off / 100;
    } else if (coupon.percent_off) {
      price -= price * (coupon.percent_off / 100);
    }
  }

  const domainName = isMarketingSite ? 'members' : 'www';
  return (
    <Box p={3} bgcolor="#fff">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="h3">
          Your order
        </Typography>
      </Box>

      <Divider spacing={1} />

      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <Typography variant="subtitle1" component="p" className={classes.inheritDisplay}>
          Plan:
        </Typography>

        <Box
          bgcolor={`${product.planStyles.color}1A`}
          color={product.planStyles.color}
          ml={1}
          px={2}
        >
          {product.name.toUpperCase()}
        </Box>
      </Box>

      {!isFreePlan && (
        <Typography variant="subtitle1" component="p">
          Total: <span style={{ fontWeight: '700' }}>${formatPrice(price)}</span>
        </Typography>
      )}

      <Typography variant="subtitle1" component="p">
        Email: {user.username}
      </Typography>

      <Divider spacing={1} />

      <Typography variant="subtitle1" component="p">
        Some friendly reminders:
      </Typography>

      <ul>
        {isFreePlan && (
          <li>
            <Typography variant="subtitle2" gutterBottom>
              You can upgrade your plan at any time at{' '}
              <Link to="/subscribe">https://{domainName}.alephbeta.org/subscribe</Link>
            </Typography>
          </li>
        )}
        {!isFreePlan && product.isRecurring && (
          <li>
            <Typography variant="subtitle2" gutterBottom>
              Your payment method will be automatically charged ${formatPrice(price)}{' '}
              {billingCycle ? `per ${billingCycle}` : null}
            </Typography>
          </li>
        )}
        {product.isRecurring && !isFreePlan && (
          <li>
            <Typography variant="subtitle2" gutterBottom>
              Your subscription will continue until you cancel
            </Typography>
          </li>
        )}
        <li>
          <Typography variant="subtitle2" gutterBottom>
            Still have questions? You can always reach out to us at&nbsp;
            <Link to="mailto:info@alephbeta.org?Subject=Help">info@alephbeta.org</Link>
          </Typography>
        </li>
      </ul>
    </Box>
  );
};

CheckoutConfirmationSummary.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    yearlyPrice: PropTypes.number.isRequired,
    monthlyPrice: PropTypes.number.isRequired,
    isRecurring: PropTypes.bool.isRequired,
    planStyles: PropTypes.shape({
      color: PropTypes.string.isRequired
    })
  }).isRequired,
  billingCycle: PropTypes.string,
  isPayingFees: PropTypes.bool,
  coupon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool])
};

CheckoutConfirmationSummary.defaultProps = {
  billingCycle: null,
  isPayingFees: false,
  coupon: null
};

export default CheckoutConfirmationSummary;
