import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import FavoriteIcon from '@material-ui/icons/Favorite';

import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  iconPosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CheckoutTerms = () => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="subtitle1" component="h6">
        About Your Donation
      </Typography>

      <Typography variant="caption" component="p">
        If your donation is a recurring subscription it will renew automatically, and you will be
        charged in advance. You may cancel at any time, but please note that the cancellation goes
        into effect at the start of your following billing cycle.
      </Typography>

      <Box my={1}>
        <Typography
          variant="subtitle1"
          component="p"
          align="center"
          className={classes.iconPosition}
          style={{ color: '#429c6d' }}
        >
          <LockIcon className={classes.leftIcon} /> This is a secure transaction
        </Typography>
      </Box>

      <Box my={1}>
        <Typography
          variant="subtitle1"
          align="center"
          component="h1"
          className={classes.iconPosition}
        >
          <FavoriteIcon color="secondary" fontSize="small" className={classes.leftIcon} />
          All donations are tax deductible
        </Typography>
      </Box>

      <Box my={1}>
        <Typography
          variant="subtitle1"
          align="center"
          component="h1"
          className={classes.iconPosition}
        >
          Need help?&nbsp;
          <Link to="mailto:info@alephbeta.org?subject=I%20Need%20Help!"> Contact Support</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default CheckoutTerms;
