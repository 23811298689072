/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Link as GatsbyLink, navigate } from 'gatsby';

import useCheckout from '~hooks/useCheckout';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import { getPreviousLocationData } from '~utils/previous-path-cookie';

import FlowLayout from '~components/checkout/FlowLayout';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import CheckoutConfirmationSummary from '~components/checkout/CheckoutConfirmationSummary';

import { pushDataLayerEvent } from '~utils/data-layer';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';
import useConversionFramework from '~hooks/useConversionFramework';

const IS_SKIP_ONBOARDING_FEATURE_ENABLED = isFeatureEnabled(FEATURE_FLAGS.SKIP_ONBOARDING);

const Confirmation = ({ location }) => {
  const [checkoutState] = useCheckout();
  const { isMarketingSite } = useConversionFramework();
  const { session } = useUserContext();
  const { isUserStateLoading, isCompletedOnboarding } = session;

  const { state: locationState } = location;
  const coupon = locationState && !!locationState.couponState && locationState.couponState;

  const { pathname, state } = getPreviousLocationData();

  const shouldSkipOnboarding = isCompletedOnboarding || IS_SKIP_ONBOARDING_FEATURE_ENABLED;

  useEffect(() => {
    if (isMarketingSite) {
      navigate('/');
    }
  }, [isMarketingSite]);

  useEffect(() => {
    // Somebody tried to open confirmation page without passing through checkout, kick them out
    if (!checkoutState || !checkoutState.product) {
      navigate('/', { replace: true });
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!isUserStateLoading) {
      // If the user paid with PayPal, they reached confirmation page via redirect.
      // This means we must wait until the user sync so the user ID and other properties would be known
      // before sending an event to GA.
      pushDataLayerEvent('checkout_confirmation_page_view');
    }
  }, [isUserStateLoading]);

  return (
    <FlowLayout title="Thank You" subtitle="Welcome to Aleph Beta!">
      <>
        <ButtonSubmit
          type="button"
          component={GatsbyLink}
          to={shouldSkipOnboarding ? pathname : '/onboarding/'}
          state={shouldSkipOnboarding ? state : {}}
        >
          Continue
        </ButtonSubmit>
      </>

      {checkoutState && checkoutState.product && (
        <CheckoutConfirmationSummary {...checkoutState} coupon={coupon} />
      )}
    </FlowLayout>
  );
};

export default Confirmation;
